// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-js": () => import("./../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-now-js": () => import("./../src/pages/order-now.js" /* webpackChunkName: "component---src-pages-order-now-js" */),
  "component---src-pages-synopsis-js": () => import("./../src/pages/synopsis.js" /* webpackChunkName: "component---src-pages-synopsis-js" */)
}

