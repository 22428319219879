import React, { useContext, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import ModalContext from '../store/modalContext';
import { ModalInner, ModalWrapper } from '../components/Elements';
import { trailerId } from '../consts/endpoints';

export default function Basic() {
  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.video-player')).map(
        p => new Plyr(p)
      );
    }
  }, []);

  return (
    <ModalWrapper>
      <OutsideClickHandler onOutsideClick={closeModal}>
        <ModalInner>
          <div className="video-player">
            <iframe
              src={`https://player.vimeo.com/video/${trailerId}?loop=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
              allowfull="true"
              allowtransparency="true"
              allow="autoplay"
              title="Trailer"
            ></iframe>
          </div>
        </ModalInner>
      </OutsideClickHandler>
    </ModalWrapper>
  );
}
