import 'typeface-black-ops-one';
import 'typeface-roboto';
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html { font-family: 'Roboto', sans-serif;}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

`;

export default Global;
