import React, { Fragment } from 'react';
import styled from 'styled-components';
import { socialUrls } from '../consts/urls';
import { colors } from '../consts/style';

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  .fab {
    color: white;
  }
  a {
    padding: 0.6rem 0.6rem 0.6rem 1rem;
    margin-bottom: 0.6rem;
    font-size: 2rem;
    color: white;
    background: ${colors.red};
    &:hover {
      color: white;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.facebook {
      background: #3b5998;
    }

    &.twitter {
      background: #00aced;
    }

    &.youtube {
      background: red;
      color: white;
    }

    &.instagram {
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }

    &.imdb {
      background: #f5c518;
    }
  }
  @media screen and (max-width: 42rem) {
    display: none;
  }
`;

export default function Socials() {
  return (
    <Fragment>
      <Wrapper>
        <a target="blank" className="instagram" href={socialUrls.instagram}>
          <i className="fab fa-instagram"></i>
        </a>
        <a target="blank" className="facebook" href={socialUrls.facebook}>
          <i className="fab fa-facebook"></i>
        </a>
        <a target="blank" className="youtube" href={socialUrls.youtube}>
          <i className="fab fa-youtube"></i>
        </a>
        <a target="blank" className="twitter" href={socialUrls.twitter}>
          <i className="fab fa-twitter"></i>
        </a>
        <a target="blank" className="imdb" href={socialUrls.imdb}>
          <i className="fab fa-imdb"></i>
        </a>
      </Wrapper>
    </Fragment>
  );
}
