import React, { useState, Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';

import { colors, font } from '../consts/style';
import { headerTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import { useContext } from 'react';
import ModalContext from '../store/modalContext';
import * as modalTypes from '../types/modalTypes';
import mq from '../style/mq';

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: white;

  ${Container} {
    position: relative;
  }

  ${Inner} {
    position: relative;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7.2rem;
  }
`;

const Image = styled(Img)`
  width: 20rem;
`;

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 0;
`;

const MenuButtonStyles = css`
  ${font.nav};
  padding: 0;
  outline: none;
  background: transparent;
  color: ${colors.blue};
  border: none;
`;

const Menu = styled.nav`
  a,
  button {
    ${font.nav};
    ${MenuButtonStyles};
    margin-right: 2.4rem;
    &:hover {
      color: ${colors.blue};
      text-decoration: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  ${mq.tabletSmall`
    display: none;
  `}
`;

const MenuButton = styled.button`
  ${MenuButtonStyles};
  display: none;
  ${mq.tabletSmall`
    display: flex;
  `}
`;

const MobileMenu = styled.nav`
  width: 100%;
  position: fixed;
  top: 7.2rem;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colors.blue};
  height: calc(100% - 7.2rem);
  padding: 2.4rem;
  overflow: scroll;
  a,
  button {
    ${MenuButtonStyles};
    color: white;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    &:hover {
      color: white;
      text-decoration: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bg: file(relativePath: { eq: "nav.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function Header({ location }) {
  const data = useStaticQuery(headerQuery);
  const { openModal } = useContext(ModalContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <BgImg fluid={data.bg.childImageSharp.fluid} />
          <Inner>
            <Link to="/" title="Home">
              <Image fluid={data.logo.childImageSharp.fluid} />
            </Link>
            <Menu>
              <Link to="/" title="Home">
                Home
              </Link>
              <Link to="/synopsis" title="Synopsis">
                Synopsis
              </Link>
              <Link to="/credits" title="Credits">
                Credits
              </Link>
              <button onClick={() => openModal(modalTypes.TRAILER)}>
                <span>Trailer</span>
              </button>
              <Link to="/order-now" title="Order Now">
                Order Now
              </Link>
            </Menu>
            <MenuButton onClick={() => setOpen(!open)}>
              {open ? 'Close' : 'Menu'}
            </MenuButton>
          </Inner>
        </Container>
      </Wrapper>
      {open && (
        <MobileMenu>
          <Link to="/" title="Home">
            Home
          </Link>
          <Link to="/synopsis" title="Synopsis">
            Synopsis
          </Link>
          <Link to="/credits" title="Credits">
            Credits
          </Link>
          <button onClick={() => openModal(modalTypes.TRAILER)}>
            <span>Trailer</span>
          </button>
          <Link to="/order-now" title="Order Now">
            Order Now
          </Link>
        </MobileMenu>
      )}
    </Fragment>
  );
}

Header.propTypes = headerTypes;
