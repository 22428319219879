import styled from 'styled-components';

import { font, z, bz } from '../consts/style';
import { fadeInUp } from '../style/animations';

export const Container = styled.div`
  padding: 0 2.4rem;
  width: 100%;
`;

export const Inner = styled.div`
  width: 120rem;
  max-width: 100%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  ${z.modalBackground};
`;

export const ModalInner = styled.div`
  background: white;
  position: relative;
  max-height: 100%;
  width: 80rem;
  animation: ${fadeInUp} 0.3s;

  @media screen and (max-width: 800px) {
    width: 100vw;
  }
  ${bz};
  ${font.h1};
  ${z.modal};
`;
